import 'classlist.js';
import 'core-js/es6/array';
import 'core-js/es6/date';
import 'core-js/es6/function';
import 'core-js/es6/map';
import 'core-js/es6/math';
import 'core-js/es6/number';
import 'core-js/es6/object';
import 'core-js/es6/parse-float';
import 'core-js/es6/parse-int';
import 'core-js/es6/reflect';
import 'core-js/es6/regexp';
import 'core-js/es6/set';
import 'core-js/es6/string';
import 'core-js/es6/symbol';
import 'core-js/es6/weak-map';
import 'core-js/es7/array';
import 'core-js/es7/reflect';
import 'element-remove';
import 'hammerjs';
import 'intersection-observer';
import 'web-animations-js';
import 'zone.js/dist/zone';

import * as process from 'process';

(window as any).__Zone_enable_cross_context_check = true;
(window as any).global = window;
window['process'] = process;

(window as any).process = {
	env: { DEBUG: undefined },
};

if (!Element.prototype.matches) {
	Element.prototype.matches = (Element.prototype as any).msMatchesSelector;
}
